.page {
  
  margin-top: 10rem;
  
  @include media-breakpoint-between(xs, md) {
    margin-top: 3rem;
  }
  @include element('wrapper') {

    @include make-container();

    @include element('header') {
      @include make-row();
      
      @include element('left') {
        @include make-col-ready();
        @include make-col(3);

        @include media-breakpoint-between(xs, md) {
          @include make-col(12);
          
          margin-bottom: 2rem;
        }

        padding-top: 1.5rem;

        > h3 {
          color: color(page-header-color);
          font-size: 1.5rem;
        }
      }

      @include element('right') {
        @include make-col-ready();
        @include make-col(9);

        @include media-breakpoint-between(xs, md) {
          @include make-col(12);
        }

        > h1 {
          font-family: $secondary-font;
          font-size: 2.5rem;
          
        }

        > p {
          font-family: $page-font;
          font-size: 1.7rem;
          line-height: 2.5rem;
          color: color(page-text-color);
          margin-bottom: 2rem;
          margin-top: 2rem;

          &:last-child {
            margin-bottom: 10rem;
          }

          &.bold {
            font-weight: 700;
            color: color(black)
          }
        }

        > ul {
          margin-bottom: 10rem;
          li {
            font-family: $page-font;
            font-size: 1.7rem;
            color: color(page-text-color);
            margin-bottom: 2rem;
            padding-left: 4rem;
            position: relative;

            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 0;
              width: 30px;
              height: 2px;
              background-color: color(primary-color);
            }
          }
        }
      }
      
    }

    @include element('people') {
      @include make-row();
      
      @include element('card') {
        @include make-col-ready();
        @include make-col(4);

        @include media-breakpoint-between(xs, md) {
          @include make-col(12);
          
          margin-top: 2rem;
          margin-bottom: 2rem;

          &:last-child() {
            margin-bottom: 4rem;
          }
        }

        display: flex;
        align-items: center;
        margin-bottom: 8rem;
        margin-top: 8rem;
        

        > img {
          width: 60%;
          margin-right: 2rem;

          @include media-breakpoint-between(xs, md) {
            width: 200px;
          }
        }

        > a {
          font-family: $secondary-font;
          font-size: 1.3rem;
          position: relative;
          > h2 > span {
            font-family: $primary-font;
            font-size: 1.2rem;
          }

          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: -60px;
            width: 140px;
            height: 2px;
            background-color: color(primary-color);
            z-index: 999;

            @include media-breakpoint-between(xs, md) {
              top: 30px;
              bottom: none;
            }
          }

          &:hover {
            color: color(primary-color);
          }
        }
      }
    }

    @include element('hero-image') {
      @include make-row();
      justify-content: center;

      @include element('image') {
        @include make-col-ready();
        @include make-col(10);

        
        margin-bottom: 10rem;
        position: relative;

        &:before {
          position: absolute;
          content: '';
          background-color: color(primary-color);
          width: 200px;
          height: 200px;
          top: -10px;
          left: 0px;
          z-index: -1;

          @include media-breakpoint-between(xs, sm) {
            width: 150px;
            height: 140px;
            top: -16px;
            left: -3px;
          }
        }
        > img {
          width: 60%;
          
          &.doctor {
            width: 60%;
          }
          
        }
      }
    }
  }

  

  
}