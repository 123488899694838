.mobile {
  display: none;

  @include media-breakpoint-between(xs, md) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: static;
    height: 100vh;

    @include element('nav-button') {
      position: relative;
      font-size: 1.5rem;
      letter-spacing: .2px;
      font-family: $font-regular;
      cursor: pointer;
      padding: 0 40px;

      span {
        position: absolute;
        content: "";
        width: 25px;
        height: 3px;
        left: 10px;
        top: 50%;
        background: color(primary-color);
        transform: translateZ(0);
        -webkit-transform: translateZ(0);
        transition: .3s;
        -webkit-transition: .3s;

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 3px;
          left: 0;
          background-color: color(primary-color);
          top: -6px;
          -webkit-transform: translateZ(0);
          transform: translateZ(0);
          -webkit-transition: .3s;
          transition: .3s;
        }

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 3px;
          left: 0;
          background-color: color(primary-color);
          bottom: -6px;
          -webkit-transform: translateZ(0);
          transform: translateZ(0);
          -webkit-transition: .3s;
          transition: .3s;
        }
      }

      &.active {
        span {
          transform: translateY(-50%) rotate(45deg);
          -webkit-transform: translateY(-50%) rotate(45deg);

          &::after {
            transform: translateY(-6px) rotate(90deg);
            -webkit-transform: translateY(-6px) rotate(90deg);
          }

          &::before {
            transform: translateY(6px);
            -webkit-transform: translateY(6px);
          }
        }
      }
    }

    @include element('menu') {
      position: absolute;
      width: 100%;
      height: 100vh;
      left: -100vw;
      top: 7.5rem;
      background-color: color(bg);
      transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transition: .5s;
      -webkit-transition: .5s;

      &.active {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        left: 0;
        z-index: 9999;
      }

      nav {
        ul {
          padding: 2rem 1.5rem;

          > li {
            margin-bottom: 1.2rem;
            font-size: 1.5rem;

            &.dropdown {

              > .dropdown-menu-psiko, .dropdown-menu-tedavi, .dropdown-menu-articles {
                position: absolute;
                width: 100%;
                height: 100vh;
                left: -100vw;
                top: 0rem;
                background-color: color(bg);
                transform: translateX(-100%);
                -webkit-transform: translateX(-100%);
                transition: .5s;
                -webkit-transition: .5s;

                &.active {
                  -webkit-transform: translateX(0);
                  transform: translateX(0);
                  left: 0;
                  z-index: 9999;
                }

                ul {
                  > li {

                    &.dropdown-menu__back-link {
                      font-family: $font-bold;
                      color: color(primary-color);
                    }
                    
                  }
                }
                
              }
              
            }
          }
        }
      }
    }
  }
}