footer {
  width: 100%;
  background-color: color(primary-color);
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;

  display: flex;
  justify-content: center;

  > h2 {
    color: color(white);
    font-family: $secondary-font;
  }

  @include media-breakpoint-between(xs, md) {
    width: 100vw;
  }
}