.hero {
  
  margin-top: 4rem;
  margin-bottom: 4rem;
  
  text-align: center;
  @include max-screen($sm-width) {
    width: 100vw;
    padding: 0 1.3rem;
    margin-bottom: 2rem;
  }

  > h1 {
    font-family: $secondary-font;
    font-size: 2rem;
    
    > span {
      color: color(white);
      background-color: color(primary-color);
    }
  }

  @include element('image') {
    width: 100%;
    margin: 0 auto;
    margin-top: 4rem;
    margin-bottom: 2rem;
    position: relative;
    padding-left: 1.5rem;
    padding-right: 1.5rem;


    @include media-breakpoint-between(xs, md) {
      padding: 0;
      margin-bottom: 1.2rem;
    }
    
    
    > img {
      width: 100%;
    }

    // &:before {
    //   position: absolute;
    //   content: '';
    //   background-color: color(primary-color);
    //   width: 500px;
    //   height: 500px;
    //   top: -10px;
    //   left: -10px;
    //   z-index: -1;
    // }

    @include max-screen($sm-width) {
      width: 100%;

      > img {
        width: 100%;
      }
  
      // &:before {
      //   position: absolute;
      //   content: '';
      //   background-color: color(primary-color);
      //   width: 200px;
      //   height: 150px;
      //   top: -7px;
      //   left: 10px;
      //   z-index: -1;
      // }
    }
  }

}