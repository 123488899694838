//------------------------------------------------------------
// = RESET
//------------------------------------------------------------

html {
	font-size: 62.5%;
}

body {
	font-family: $primary-font;
	color: color(font-primary);
	text-size-adjust: 100%;
	-webkit-overflow-scrolling: touch;
	-webkit-font-smoothing: subpixel-antialiased;
	background-color: color(bg);
	margin: 0;

	
}

@include media-breakpoint-between(xs, md) {
	html.noscroll {
		overflow: hidden;
	}

	body.noscroll {
		overflow: hidden;
		height: 100vh;
		width: 100vw;
		position: fixed;
	}

	.open {
		opacity: 1;
		right: 0;
	}
}

input, select, textarea {
	font-family: $primary-font;
	color: color(font-primary);
}

input, select, textarea, :focus {
	appearance: none;
	-webkit-appearance: none;
}

a {
	color: color(first); 
	text-decoration: none
}

img {
	border: 0;
}

figure {
	margin: 0;
}

h1, h2, h3, h4, h5, h6, ul, label {
	margin: 0;
	padding: 0;
}
ul {
	list-style: none;
}
::selection {
	color: color(white);
	background: color(primary-color);
}

::-moz-selection {
	color: color(white);
	background: color(primary-color);
}

textarea {
	resize: none;
}


p {
	margin: 0;
}

a {
	color: inherit;
	&:hover {
		color: inherit;
		text-decoration: none;

	}
}



.autosize {
	resize: none;
	overflow: hidden;
}




// NOTE: Animate edit
.animated {
	animation-duration : 50ms !important;
}


// NOTE: Pace.js conf
.pace {
	-webkit-pointer-events: none;
	pointer-events: none;
	
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.pace-inactive {
	display: none;
}

.pace .pace-progress {
	background: #C528D8;
	position: fixed;
	z-index: 2000;
	top: 0;
	right: 100%;
	width: 100%;
	height: 2px;
}

.pace-running > *:not(.pace) {
	display: none;
}

// NOTE: Outline styles

* {
	&:focus {
		outline: auto 2px Highlight; // for non-webkit browsers
		outline: auto 5px -webkit-focus-ring-color; // for webkit browsers
	}
}

.visuallyHidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}



a {
	&.skip-main {
		left:-999px;
		position:absolute;
		top:auto;
		width:1px;
		height:1px;
		overflow:hidden;
		z-index:-999;
		&:focus, &:active {
			color: #fff;
			background-color:#000;
			left: auto;
			top: auto;
			width: 30%;
			height: auto;
			overflow:auto;
			margin: 10px 35%;
			padding:5px;
			border-radius: 15px;
			border:4px solid yellow;
			text-align:center;
			font-size:1.2em;
			z-index:999;
		}
	}
}

h1, h2, h3, h4, h5, h6, p, article, .form {
	outline: none!important;
}


