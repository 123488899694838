@mixin element($element) {
  &__#{$element} {
    @content;
  }
}

@mixin modifier($element) {
  &--#{$element} {
    @content;
  }
}