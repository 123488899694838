.header {
  display: flex;
  background-color: color(white);
  padding-left: 4.5rem;
  // height: 10rem;
  align-items: center;
  justify-content: space-between;

  @include max-screen($sm-width) {
    height: 7.5rem;
    padding-left: 1.5rem;
    align-items: center;
    
  }

  @include element('brand') {
    min-height: 100%;
    padding: 0.5rem 0;
    >a {
      
      display: block;

      >img {
        width: 235px;

      }
    }

    @include max-screen($sm-width) {
      display: none;
    }
  }

  @include element('nav') {

    // padding-top: 3.2rem;
    

    @include max-screen($sm-width) {
      display: none;
    }

    @include element('list') {
      height: 93px;
      display: flex;
      justify-content: space-between;
      align-content: center;

      >li {
        margin-right: 3.3rem;
        font-family: $font-regular;
        font-size: 1.3rem;
        letter-spacing: 0.01rem;
        

        a {
          display: flex;
          align-items: center;
          height: 100%;
          &:hover {
            color: color(primary-color);
          }
        }


      }

    }
  }

  @include element('telno') {
    background-color: color(secondary-color);
    height: 9.5rem;
    // padding-top: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @include max-screen($sm-width) {
      display: none;
    }

    >h1 {
      
      font-size: 1.5rem;
      padding-left: 1rem;
      padding-right: 3.5rem;
      
      color: color(primary-color);

    }

  }

  .sub-nav {
    position: absolute;
    display: none;
    width: 100%;
    top: 9.5rem;
    left: 0;
    background-color: color(white);
    z-index: 9999;
    padding-left: 24rem;
    box-shadow: $regular-shadow;
    transform: scale(0);
    -webkit-transition: 1.0s ease;
    -moz-transition: 1.0s ease;
    -ms-transition: 1.0s ease;
    -o-transition: 1.0s ease;
    transition: 1.0s ease;

    &.is-active {
      display: block;
      transform: scale(1);
      -webkit-transition: 1.0s ease;
      -moz-transition: 1.0s ease;
      -ms-transition: 1.0s ease;
      -o-transition: 1.0s ease;
      transition: 1.0s ease;
      height: auto;
    }

    @include element('container') {
      @include make-container();

      @include element('inner') {
        @include make-row();

        @include element('header') {
          @include make-col-ready();
          @include make-col(12);
          margin-bottom: 2rem;

          h2 {
            color: color(primary-color);
          }
        }

        @include element('column') {
          @include make-col-ready();
          @include make-col(4);

          ul {
            >li {
              margin-bottom: 1rem;

              >a {
                &:hover {
                  color: color(primary-color);
                }
              }
            }
          }

          margin-bottom: 4rem;
        }
      }
    }
  }
}