.contact {
  
  padding-top: 10rem;

  @include media-breakpoint-between(xs, md) {
    padding-top: 3rem;
  }

  @include element('wrapper') {
    @include make-container();

    @include element('header') {
      @include make-row();
      justify-content: center;
      @include element('text') {
        @include make-col-ready();
        @include make-col(12);

        text-align: center;

        > h1 {
          font-family: $secondary-font;
          font-size: 7rem;

          @include media-breakpoint-between(xs, md) {
            font-size: 3rem;
          }
        }

        > a {
          font-size: 1.7rem;
          color: color(primary-color);
        }
      }
    }

    @include element('content') {

      margin-top: 8rem;

      @include media-breakpoint-between(xs, md) {
        margin-top: 2rem;
      }
      @include make-row();
      justify-content: center;

      @include element('card') {
        @include make-col-ready();
        @include make-col(4);

        @include media-breakpoint-between(xs, md) {
          @include make-col(12);
          margin-bottom: 2rem;
        }
        margin-bottom: 8rem;

        @include element('inner') {
          border: 1px solid color(font-secondary);
          padding: 20px;
          
          > img {
            width: 50px;
            margin-bottom: 2rem;
          }

          > h2 {
            color: color(primary-color);
            margin-bottom: 1.5rem;
            font-family: $page-font;
          }

          > a, p {
            font-size: 1.5rem;
            line-height: 2.6rem;
            font-family: $page-font;
          }
          
          .map_link {
            margin-top: 3rem;
            color: color(primary-color);
            font-family: $page-font;
          }
        }
      }
    }
  }
}