.doctors {
  margin-top: 2rem;
  margin-bottom: 2rem;

  @include media-breakpoint-between(xs, sm) {
    margin-top: 1rem;
  }

  @include element('wrapper') {
    @include make-container();

    @include element('inner') {
      @include make-row();

      @include element('card') {
        @include make-col-ready();
        @include make-col(6);

        @include media-breakpoint-between(xs, sm) {
          
          @include make-col(12);
          margin-top: 1rem;
          margin-bottom: 2rem;
        }

        display: flex;
        align-items: center;
        margin-bottom: 8rem;
        margin-top: 8rem;

        
        

        > img {
          width: 65%;
          margin-right: 2rem;

          @include media-breakpoint-between(xs, md) {
            width: 150px;
          }
        }

        > a {
          font-family: $secondary-font;
          font-size: 1.3rem;
          position: relative;
          > h2 > span {
            font-family: $primary-font;
            font-size: 1.2rem;
          }

          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: -60px;
            width: 140px;
            height: 2px;
            background-color: color(primary-color);
            z-index: 999;
          }

          &:hover {
            color: color(primary-color);
          }
        }
      }
    }
  }
}