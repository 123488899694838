$colors: (
  white: #FFFFFF,
  black: #000000,
  bg: #F8F8F8,
  border: #E0E0E0,
  hamburger-menu: #020202,
  primary-color: #43873D,
  secondary-color: #F4EFEF,
  primary-red: #E42727,
  primary-red-lighten: #E45151,

  font-primary: #4A4A4A,
  font-secondary: #AAAAAA,
  
  card-overlay: rgba(71, 71, 71, .5),

  page-text-color: #777777,
  page-header-color: #999999
  
)