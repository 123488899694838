@include font-face(FuturaMedium, '../fonts/futura-medium', null, null, woff woff2);
@include font-face(FuturaBold, '../fonts/futura-bold', null, null, woff woff2);
@include font-face(PlayFair, '../fonts/playfair', null, null, woff woff2 ttf svg);
@include font-face(SofiaPro, '../fonts/sofiapro', null, null, woff woff2 ttf svg);

$font-regular: 'FuturaMedium';
$font-bold: 'FuturaBold';

$primary-font: 'FuturaMedium';

$secondary-font: 'PlayFair';
$page-font: 'SofiaPro';