section.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: calc(100vh - 100px);
  width: 100vw;

  @include element('left') {
    position: relative;
    overflow: hidden;

    & .content__left__carousel {
      height: 100%;
      position: relative;
      width: 100%;

      .owl-stage {
        height: calc(100vh - 100px);

        .owl-item img {
          display: block;
          width: 100%;
          height: 200%;
          object-fit: cover;
          
          
        }
      }
    }
  }

  @include element('middle') {
    background-color: color(primary-color);
    display: grid;

  }

  @include element('right') {
    position: relative;
    background-color: color(primary-color);
    background: radial-gradient(circle, rgba(79,172,69,1) 0%, rgba(67,135,61,1) 35%);
    display: grid;
    grid-template-columns: repeat(3, 33%);
    grid-template-rows: repeat(3, 33%);
    // > .content__right__picture {
    //   height: 100%;
    //   width: 100%;
    //   position: relative;
    //   overflow: hidden;

    //   > .content__right__picture__bg {
    //     position: absolute;
    //     background: url('../images/m_temiz_front.png') no-repeat center center;
    //     background-size: cover;
    //     width: 100%;
    //     height: 100%;
    //   }
    // }

    .bird-icon {
      grid-column: 2;
      grid-row: 2;
    }

    .bird-icon-2 {
      padding-top: 10px;
      padding-left: 20px;
      align-self: self-end;
    }

    > h1 {
      grid-column: 2/4;
      grid-row: 3;
      align-self: flex-end;
      font-family: $secondary-font;
      font-size: 2rem;
      letter-spacing: 0.2rem;
      padding-bottom: 20px;
      color: color(white);
      line-height: 3rem;

      span {
        
        position: relative;
        background-color: color(secondary-color);
        color: color(primary-color);
        padding: 3px;
      }
    }
  }
}